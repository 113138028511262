<template>
  <div class="display">
    <div class="display-header">
      <el-button @click="addDisplayWrap = true;add=true">添加类型</el-button>
      <el-dialog title="陈列照" :visible.sync="addDisplayWrap" width="35%">
        <el-form ref="form" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="displayForm.name"></el-input>
          </el-form-item>
          <el-form-item label="关联分类">
            <el-select v-model="displayForm.type" placeholder="类型" @change="handleChangeType">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="displayForm.typeids" collapse-tags multiple placeholder="分类id"
                       style="padding-left: 10px">
              <el-option
                v-for="item in typeids"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!--
          <el-form-item label="tips">
            <span style="color: #999">分类关联商品为中级分类编码，预售分类为分类id，系列商品为系列id，使用英文逗号(,)分割</span>
          </el-form-item>-->
          <el-form-item label="封面图">
            <el-upload
              class="avatar-uploader"
              :action="this.$root.DownUrl + '/a1/upload/file'"
              :headers="uploadHeaders"
              :show-file-list="false"
              :on-success="handleImgSuccess"
              :auto-upload="true"
              list-type="picture"
            >
              <img v-if="displayForm.src" :src="displayForm.src" class="avatar" alt="" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
				  <el-button @click="addDisplayWrap = false">取 消</el-button>
				  <el-button type="primary" @click="submitDisplayWrap" v-if="add">提 交</el-button>
				  <el-button type="primary" @click="editDisplayWrap" v-if="!add">编 辑</el-button>
			  </span>
      </el-dialog>
    </div>
    <div class="display-content">
      <el-table :data="displayData">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="categoryName" label="标题"></el-table-column>
        <el-table-column prop="categoryImg" label="展示图">
          <template v-slot="{row}">
            <el-image :src="row.categoryImg" alt="" style="width: 150px;height: 200px"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template v-slot="{row}">
            <span v-for="i in typeOptions" :key="i.value">
              {{ row.categoryType === i.value ? i.label :"" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="类型id">
          <template v-slot="{row}">
            {{row.typeids}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template v-slot="{row}">
            <el-button type="primary" plain @click="handleEdit('edit',row)">编辑</el-button>
            <el-button type="primary" plain @click="handleEdit('info',row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div ref="displayData-footer" class="displayData-footer" v-if="displayData.length > 0">
          <el-pagination :current-page="displayDataPageInfo.page"
                         :page-sizes="[10, 20, 50, 100, 500, 1000]"
                         :page-size="displayDataPageInfo.pagesize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="displayDataPageInfo.total"
                         @current-change="handleChangeCurrentPage"
                         @size-change="handleSizeChange"
                         background />
        </div>
    </div>
  </div>
</template>

<script>
import display from "./js/index";

export default display
</script>

<style scoped lang="scss">
@import "./css/index.scss";
</style>
