export default {
    name: "index",
    data() {
      return {
        addDisplayWrap: false,
        add: true,
        displayForm: {
          name: "",
          typeids: "",
          categoryImg: "",
          src: "",
          type: 1,
        },
        typeOptions: [{
          value: 1,
          label: "预售类别",
        }, {
          value: 2,
          label: "系列商品",
        }, {
          value: 3,
          label: "全部商品",
        }],
        typeids:[],
        displayData: [],
        displayDataPageInfo: {
            page: 1,
            pagesize: 10,
            total: 0,
        },
        currentid: 0,
        uploadHeaders: {
          Authorization: JSON.parse(sessionStorage.getItem("token")),
        },
      };
    },
    created() {
      this.handleGetDisplay();
      this.handleChangeType(1)
    },
    methods: {
      submitDisplayWrap() {
  
        let params={
          name: this.displayForm.name,
          typeids: this.displayForm.typeids.sort().join(","),
          Images: this.displayForm.src,
          categorytype:this.displayForm.type
        };
  
        this.$axios.post("/a1/display/category", params).then(res => {
          this.$message.success("添加成功");
          this.addDisplayWrap=false;
          this.handleGetDisplay();
        }).catch(err => {
          this.$message.error(err.response.data.message);
        });
  
      },
      editDisplayWrap() {
  
        let params={
          name: this.displayForm.name,
          typeids: this.displayForm.typeids.sort().join(","),
          Images: this.displayForm.src,
          id: this.currentid,
          categorytype:this.displayForm.type
        };
  
        this.$axios.put("/a1/display/category", params).then(res => {
          this.$message.success("更新成功");
          this.addDisplayWrap=false;
          this.handleGetDisplay();
        }).catch(err => {
          this.$message.error(err.response.data.message);
        });
      },
      handleGetDisplay() {
  
        let params={
          page: this.displayDataPageInfo.page,
          pagesize: this.displayDataPageInfo.pagesize,
        };
  
        this.$axios.get("/a1/display/category", { params: params }).then(res => {
          const { data }=res.data;
          this.displayData=data.data;
          this.displayDataPageInfo.total = data.total
        });
      },
      handleEdit(type, row) {
        switch (type) {
          case "edit":
            this.handleChangeType(row.categoryType)
            this.addDisplayWrap=true;
            this.add=false;
            this.displayForm.name=row.categoryName;
            this.displayForm.src=row.categoryImg;
            this.currentid=row.id;
            this.displayForm.typeids = row.typeids.split(",").map((i) => {return parseInt(i)})
            this.displayForm.type = row.categoryType
            break;
          case "info":
            this.$router.push({ path: "/admin/goods/displayInfo", query: { "categoryid": row.id,"categoryType":row.categoryType } });
            break;
        }
      },
      handleImgSuccess(res, file) {
        if (res.error === 0) this.displayForm.src=JSON.parse(JSON.stringify(res.data));
        this.displayForm.src=file.response.data;
      },
      handleChangeType(val) {
        this.displayForm.typeids = ""
        this.$axios.get("/a1/display/category/type", { params: { id : parseInt(val) } }).then(res => {
          const {data} =res.data
          this.typeids = data
        });
      },
      handleSizeChange(val) {
        this.displayDataPageInfo.pagesize=val;
        this.handleGetDisplay();
      },
      handleChangeCurrentPage(page) {
        this.displayDataPageInfo.page=page;
        this.handleGetDisplay();
      },
    },
  };